import React from 'react'
import { Flex, Box } from 'reflexbox'

import NotFoundModule from 'src/storyblok/GlobalModules/404/404'
import SEO from 'src/components/SEO/SEO'

import * as styles from './404.module.scss'

const NotFoundPage = ({ pageContext }: any) => {
  const { data } = pageContext
  const { content } = data

  return (
    <React.Fragment>
      <SEO title={'404'} />
      <NotFoundModule blok={content} />
    </React.Fragment>
  )
}

export default NotFoundPage
